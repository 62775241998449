.checked-render {
    display: grid;
    grid-template-columns: repeat(var(--grid-columns), 1fr);
    grid-template-rows: repeat(var(--grid-rows), 1fr);
    gap: var(--grid-row-gap) var(--grid-column-gap);
    background: var(--grid-color);
    top: 0;
    left: 0;
    position: fixed;
    z-index: -10;
    height: var(--image-height-px);
    width: var(--image-width-px);

    .slot {
        background: #fff;
        background-image: var(--image);
        background-size: cover;
    }

    &.edges-{
        &corner {
            padding: var(--grid-column-gap) var(--grid-row-gap) 0 0;
        }

        &vertical, &both {
            padding-top: calc(var(--grid-row-gap) / 2);
            padding-bottom: calc(var(--grid-row-gap) / 2);
        }

        &horizontal, &both {
            padding-right: calc(var(--grid-column-gap) / 2);
            padding-left: calc(var(--grid-column-gap) / 2);
        }
    }

    &.normal-map {
        background: #fff;

        .slot {
            background: #000;
            background-image: none;

            img {
                visibility: hidden;
            }
        }
    }
}
