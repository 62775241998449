$headerHeight: 60px;
$footerHeight: 30px;

body {
    overflow-x: hidden;
}

.layout {
    position: relative;
    z-index: 10;

    > header {
        background: #fff;
        display: flex;
        height: $headerHeight;
        align-items: center;
        justify-content: center;
        width: 100%;
        box-shadow:
            0px 0.4px 0.6px rgba(0, 0, 0, 0.011),
            0px 0.9px 1.5px rgba(0, 0, 0, 0.016),
            0px 1.8px 2.9px rgba(0, 0, 0, 0.02),
            0px 3.1px 5.1px rgba(0, 0, 0, 0.024),
            0px 5.8px 9.6px rgba(0, 0, 0, 0.029),
            0px 14px 23px rgba(0, 0, 0, 0.04)
        ;
        position: relative;
        z-index: 15;

        > h1 {
            font-size: 2rem;
        }
    }

    > footer {
        display: flex;
        gap: 10px 20px;
        height: $footerHeight;
        align-items: center;
        justify-content: center;
        width: 100%;
        background: rgb(245, 245, 245);
        border-top: 1px solid rgb(216, 216, 216);
        font-size: 0.8rem;

        * {
            color: rgb(119, 119, 119);
        }
    }

    > main {
        background: #fff;
        padding: 40px 0;
        min-height: calc(100vh - #{$headerHeight} - #{$footerHeight});
    }
}